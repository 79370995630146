import React, { useState, useEffect } from 'react';
import {
  Grid,
  Container,
  ThemeProvider,
  Typography,
  TextField,
  MenuItem,
  makeStyles,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import ThreeButtonsGroup from '../../components/FormControl/ThreeButtonsGroup';
import { marginStyles } from '../../common/Styles';
import { Theme } from '../../Theme';
import withLayout from '../Layout/WithLayout';
import DefaultLayout from '../Layout/Default';
import { FormSteps, REQUIREDFIELD } from '../../constant/constants';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 'calc(100vh - 150px)',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      margin: '30px',
      flexGrow: '1',
      width: 'calc(100% - 60px)',
      maxWidth: '950px',
      minHeight: 'calc(100vh - 210px)',
    },
  },
  grid: {
    margin: '20px 22px',
    padding: 0,
    width: 'calc(100% - 44px)',
    maxWidth: '700px',
    [theme.breakpoints.up('sm')]: {
      margin: '-8px',
    },
  },
  h3: {
    margin: '0',
    marginBottom: '20px',
  },
  h4: {
    margin: '0',
  },
  selectLabel: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    lineHeight: '24px',
    margin: '0',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '20px',
    },
  },
  buttonContainer: {
    [theme.breakpoints.up('sm')]: {
      margin: '0 30px',
    },
  },
  radioLabel: {
    margin: 0,
  },
  errorText: {
    color: '#f44336',
    fontSize: '0.75rem',
    margin: '0',
    display: 'block',
  },
}));

interface ISocialMediaProps {
  nextStep: () => void;
  prevStep: () => void;
  saveAndExit: () => void;
  handleChange: (input: string) => (e: any) => void;
  formValues: string[];
  influencer?: boolean;
  handleInfluencer: (selection: boolean | undefined) => void;
  referrer: string;
  referrerName?: string;
  values: any;
}

const SocialMedia = (props: ISocialMediaProps) => {
  const [displayList, setDisplayList] = useState<string[]>([]);
  const [isSelectInputInvalid, setIsSelectInputInvalid] = useState(false);
  const [isFriendNameInvalid, setIsFriendNameInvalid] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setDisplayList(props.formValues);
  }, [props.formValues]);

  const handleNext = () => {
    if (props.values.referrer) {
      if (props.values.referrer === 'I was referred by a friend') {
        if (props.values.referrerName && props.values.referrerName !== '') {
          props.nextStep();
        } else {
          setIsFriendNameInvalid(true);
        }
      } else {
        props.nextStep();
      }
    } else {
      setIsSelectInputInvalid(true);
    }
  };

  const handleBack = () => {
    props.prevStep();
  };
  return (
    <React.Fragment>
      <ThemeProvider theme={Theme}>
        <Container className={classes.container} id="social-media-wrapper">
          <Grid container spacing={2} className={classes.grid}>
            <Grid item xs={12}>
              <Typography variant="h3" className={classes.h3}>
                Social Media
              </Typography>

              <Typography variant="h4" className={classes.h4}>
                If you’d like to share your social media handles, please do so
                here.
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                id="instagram"
                label="Instagram Handle"
                variant="outlined"
                onChange={props.handleChange('instagram')}
                defaultValue={props.values.instagram}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                id="tikTok"
                label="TikTok Handle"
                variant="outlined"
                onChange={props.handleChange('tikTok')}
                defaultValue={props.values.tikTok}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                id="linkedIn"
                label="LinkedIn URL"
                variant="outlined"
                onChange={props.handleChange('linkedIn')}
                defaultValue={props.values.linkedIn}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                id="facebook"
                label="Facebook URL"
                variant="outlined"
                onChange={props.handleChange('facebook')}
                defaultValue={props.values.facebook}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h4" className={classes.selectLabel}>
                How did you hear about XPO Talent?*
              </Typography>
              <TextField
                value={props.referrer ? props.referrer : ''}
                onChange={(e) => {
                  setIsSelectInputInvalid(false);
                  props.handleChange('referrer')(e);
                }}
                variant="outlined"
                label="Please select"
                error={isSelectInputInvalid}
                helperText={isSelectInputInvalid && REQUIREDFIELD}
                select
                fullWidth
              >
                {displayList.map((item: string, index: number) => {
                  return (
                    <MenuItem
                      value={item}
                      key={index}
                      style={{ fontSize: '14px' }}
                    >
                      {item}
                    </MenuItem>
                  );
                })}
              </TextField>
              {props.values.referrer === 'I was referred by a friend' && (
                <div style={{ marginTop: '10px' }}>
                  <TextField
                    id="referrerName"
                    label="Friend's Full Name"
                    variant="outlined"
                    error={isFriendNameInvalid}
                    helperText={isFriendNameInvalid && REQUIREDFIELD}
                    onChange={(e) => {
                      if (e.currentTarget.value.length > 0) {
                        setIsFriendNameInvalid(false);
                      }
                      props.handleChange('referrerName')(e);
                    }}
                    defaultValue={props.values.referrerName}
                    fullWidth
                  />
                </div>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" className={classes.h4}>
                Do you have 2,000+ followers and an interest in Influencer
                Roles?
              </Typography>
              <FormControlLabel
                style={{ width: '50%' }}
                control={
                  <Checkbox
                    checked={props.influencer === true}
                    onChange={() => {
                      let carResponse;
                      if (props.influencer !== true) {
                        carResponse = true;
                      }
                      props.handleInfluencer(carResponse);
                    }}
                    name="influencer"
                    color="primary"
                  />
                }
                label="Yes"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.influencer === false}
                    onChange={() => {
                      let carResponse;
                      if (props.influencer !== false) {
                        carResponse = false;
                      }
                      props.handleInfluencer(carResponse);
                    }}
                    name="influencer"
                    color="primary"
                  />
                }
                label="No"
              />
            </Grid>
          </Grid>
          <ThreeButtonsGroup
            nextStep={handleNext}
            prevStep={handleBack}
            saveAndExit={props.saveAndExit}
          />
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default withLayout(SocialMedia, DefaultLayout, {
  Step: FormSteps.SocialMedia,
});
