import React, { useState } from 'react';
import {
  FormControlLabel,
  Grid,
  Checkbox,
  Container,
  makeStyles,
  ThemeProvider,
  Typography,
  TextField,
  MenuItem,
} from '@material-ui/core';
import ThreeButtonsGroup from '../../components/FormControl/ThreeButtonsGroup';
import Modal, { ModalTypes } from '../XPO/Modal';
import { Theme } from '../../Theme';
import withLayout from '../Layout/WithLayout';
import DefaultLayout from '../Layout/Default';
import { FormSteps } from '../../constant/constants';

interface ITermsandConditionsProps {
  nextStep: () => void;
  prevStep: () => void;
  saveAndExit: () => void;
  handleCheckbox: (input: string) => (e: any) => void;
  handleChange: (input: string) => (e: any) => void;
  values: any;
}

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 'calc(100vh - 150px)',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      margin: '30px',
      flexGrow: '1',
      width: 'calc(100% - 60px)',
      maxWidth: '950px',
      minHeight: 'calc(100vh - 210px)',
    },
  },
  grid: {
    margin: '20px 22px',
    padding: 0,
    width: 'calc(100% - 44px)',
    [theme.breakpoints.up('sm')]: {
      margin: '-8px',
    },
  },
  h3: {
    margin: '0',
    marginBottom: '20px',
  },
  h4: {
    margin: '0',
  },
  selectLabel: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    lineHeight: '24px',
    margin: '0',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '20px',
    },
  },
  visaSelect: {
    maxWidth: 'calc(100vw - 60px)',
  },
}));

const visaList = [
  "I'm an Australian citizen",
  "I'm a permanent resident and/or NZ citizen",
  'I have a family/partner visa with no restrictions',
  'I have a graduate tempory work visa',
  'I have a holiday temporary work visa',
  'I have a temporary protection or safe haven visa',
  'I have a temporary visa with no restrictions',
  'I have a temporary visa with restrictions on hours',
  'I require sponsorship to work',
];

const TermsandConditions = (props: ITermsandConditionsProps) => {
  const classes = useStyles();

  const [openModal, setOpenModal] = useState(false);
  const [modalType] = useState(ModalTypes.CONFIRM);
  const [modalTitle] = useState(
    'Are you sure you want to submit your application?'
  );
  const [modalMessage] = useState(
    'Once you click OK your application will be submitted and you will NOT be able to edit it. Please make sure all your details are correct before clicking OK.'
  );

  const confirmFinish = () => {
    setOpenModal(true);
  };

  const handleNext = () => {
    setOpenModal(false);
    props.nextStep();
  };

  const handleCancel = () => {
    setOpenModal(false);
  };

  const handleBack = () => {
    props.prevStep();
  };

  return (
    <React.Fragment>
      {openModal && (
        <Modal
          type={modalType}
          open={openModal}
          title={modalTitle}
          content={modalMessage}
          handleClose={handleCancel}
          handleConfirm={handleNext}
        />
      )}
      <ThemeProvider theme={Theme}>
        <Container className={classes.container}>
          <Grid container spacing={2} className={classes.grid}>
            <Grid item xs={12}>
              <Typography variant="h3" className={classes.h3}>
                Terms & Conditions
              </Typography>

              <Typography variant="h4" className={classes.h4}>
                Final step—please agree to the following Terms & Conditions.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.values.ageConfirmation}
                    onChange={props.handleCheckbox('ageConfirmation')}
                    name="ageConfirmation"
                    color="primary"
                  />
                }
                label="I confirm that I am over 18 years of age"
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.values.personalDetailsConfirmation}
                    onChange={props.handleCheckbox(
                      'personalDetailsConfirmation'
                    )}
                    name="personalDetailsConfirmation"
                    color="primary"
                  />
                }
                label="I consent to submitting my personal details for the purpose of this application"
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.values.correctInformationConfirmation}
                    onChange={props.handleCheckbox(
                      'correctInformationConfirmation'
                    )}
                    name="correctInformationConfirmation"
                    color="primary"
                  />
                }
                label="I agree that all information provided in this application is accurate and correct"
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.values.rightToWorkConfirmation}
                    onChange={props.handleCheckbox('rightToWorkConfirmation')}
                    name="rightToWorkConfirmation"
                    color="primary"
                  />
                }
                label="I confirm that I have the right to work in Australia"
              />
            </Grid>
            {props.values.rightToWorkConfirmation && (
              <Grid item xs={12} md={6}>
                <Typography variant="h4" className={classes.selectLabel}>
                  Please select your current visa status.*
                </Typography>
                <TextField
                  value={props.values.visaStatus ? props.values.visaStatus : ''}
                  onChange={(e) => {
                    props.handleChange('visaStatus')(e);
                  }}
                  className={classes.visaSelect}
                  variant="outlined"
                  label="Please select"
                  select
                  fullWidth
                >
                  {visaList.map((item: string, index: number) => {
                    return (
                      <MenuItem
                        value={item}
                        key={index}
                        style={{ fontSize: '14px' }}
                      >
                        {item}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            )}
          </Grid>

          <ThreeButtonsGroup
            nextButtonDisabled={
              !(
                props.values.ageConfirmation &&
                props.values.personalDetailsConfirmation &&
                props.values.correctInformationConfirmation &&
                props.values.rightToWorkConfirmation &&
                props.values.visaStatus
              )
            }
            nextStep={confirmFinish}
            prevStep={handleBack}
            saveAndExit={props.saveAndExit}
          />
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default withLayout(TermsandConditions, DefaultLayout, {
  Step: FormSteps.TermsandConditions,
});
