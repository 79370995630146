import React, { useState, useEffect } from 'react';
import { Grid, Container, ThemeProvider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import {
  IFormValue,
  IFormValuePayload,
} from '../../interfaces/formValue.interface';
import ThreeButtonsGroup from '../FormControl/ThreeButtonsGroup';
import { Theme } from '../../Theme';
import withLayout from '../Layout/WithLayout';
import DefaultLayout from '../Layout/Default';
import { FormSteps } from '../../constant/constants';
import RadioSelectTable from '../RadioSelectTable';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 'calc(100vh - 150px)',
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    // width: '100%',
    maxWidth: '100vw',
    [theme.breakpoints.up('sm')]: {
      padding: '30px',
    },
  },
  grid: {
    margin: 0,
    width: '100%',
  },
  h3: {
    margin: '30px 30px 20px',
    [theme.breakpoints.up('sm')]: {
      margin: '0',
      marginBottom: '20px',
    },
  },
  h4: {
    margin: '0 30px',
    [theme.breakpoints.up('sm')]: {
      margin: '0',
    },
  },
}));

interface IQualificationsProps {
  nextStep: () => void;
  prevStep: () => void;
  saveAndExit: () => void;
  handleChange: (id: string, value: string, field: string) => void;
  formValues: IFormValue[];
  selectedQualifications: IFormValuePayload[];
  values: any;
}

const Qualifications = (props: IQualificationsProps) => {
  const [displayList, setDisplayList] = useState<IFormValue[]>([]);
  const classes = useStyles();

  useEffect(() => {
    setDisplayList(props.formValues);
  }, []);

  const handleNext = () => {
    props.nextStep();
  };

  const handleBack = () => {
    props.prevStep();
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={Theme}>
        <Container className={classes.container} id="qualifications-container">
          <Typography variant="h3" className={classes.h3}>
            Qualifications
          </Typography>
          <Typography variant="h5" className={classes.h4}>
            Please select your level of interest or qualification in each
            category.
          </Typography>
          <RadioSelectTable
            displayList={displayList}
            title={'Qualifications'}
            option1={'seeking qualification'}
            option2={'qualified'}
            handleSelection={props.handleChange}
            selectedItems={props.selectedQualifications}
          />
          <div style={{ marginTop: 'auto' }}>
            <ThreeButtonsGroup
              nextStep={handleNext}
              prevStep={handleBack}
              saveAndExit={props.saveAndExit}
            />
          </div>
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default withLayout(Qualifications, DefaultLayout, {
  Step: FormSteps.Qualifications,
});
