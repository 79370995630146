import React, { useState, useEffect } from 'react';
import {
  Grid,
  Container,
  ThemeProvider,
  Typography,
  TextField,
  MenuItem,
} from '@material-ui/core';
import ThreeButtonsGroup from '../../components/FormControl/ThreeButtonsGroup';
import {
  IFormValue,
  IFormValuePayload,
} from '../../interfaces/formValue.interface';
import { Theme } from '../../Theme';
import withLayout from '../Layout/WithLayout';
import DefaultLayout from '../Layout/Default';
import { FormSteps } from '../../constant/constants';
import { makeStyles } from '@material-ui/core/styles';
import RadioSelectTable from '../RadioSelectTable';
// import ExperienceDetails from '../ExperienceDetails';
// import useMediaQuery from '@material-ui/core/useMediaQuery';

interface ISkillsProps {
  nextStep: () => void;
  prevStep: () => void;
  saveAndExit: () => void;
  handleSelection: (id: string, value: string, field: string) => void;
  handleSkillDetails: (index: number, details: string) => void;
  formValues: IFormValue[];
  experiences: IFormValue[];
  handleExperience: (id: string, input: string) => void;
  selectedSkills: IFormValuePayload[];
  selectedExperiences: IFormValuePayload[];
}

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 'calc(100vh - 150px)',
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '100vw',
    [theme.breakpoints.up('sm')]: {
      padding: '30px',
    },
  },
  h3: {
    margin: '30px 30px 20px',
    [theme.breakpoints.up('sm')]: {
      margin: '0',
      marginBottom: '20px',
    },
  },
  h4: {
    margin: '0 30px',
    [theme.breakpoints.up('sm')]: {
      margin: '0',
    },
  },
  label: {
    display: 'block',
    fontFamily: 'Roboto',
    fontSize: '16px',
    lineHeight: '20px',
    paddingBottom: '5px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
    },
  },
  input: {
    display: 'block',
    padding: '10px 16px',
    width: 'calc(100% - 32px)',
    fontFamily: 'Roboto',
    fontSize: '16px',
    color: 'black',
    borderRadius: '5px',
    border: '1px solid #A9A9A9',
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
    },
    '& focus': {
      outline: '',
    },
  },
  experienceContainer: {
    margin: '20px 22px 20px 22px',
    padding: 0,
    width: 'calc(100% - 60px)',
    maxWidth: '950px',
    [theme.breakpoints.up('sm')]: {
      margin: '22px 0 22px -8px',
      width: '100%',
    },
  },
}));

const Skills = (props: ISkillsProps) => {
  const [displayList, setDisplayList] = useState<IFormValue[]>([]);
  const classes = useStyles();

  useEffect(() => {
    setDisplayList(props.formValues);
  }, []);

  return (
    <React.Fragment>
      <ThemeProvider theme={Theme}>
        <Container className={classes.container}>
          <div>
            <Typography variant="h3" className={classes.h3}>
              Skills & Experience
            </Typography>
            <Typography variant="h4" className={classes.h4}>
              Please indicate the level of skill you hold in the following
              areas.
            </Typography>
            <RadioSelectTable
              displayList={displayList}
              title={'Skills'}
              option1={'moderate'}
              option2={'proficient'}
              selectedItems={props.selectedSkills}
              handleSelection={props.handleSelection}
            />
            <Grid container className={classes.experienceContainer} spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  className={classes.h4}
                  style={{ margin: 0 }}
                >
                  Please indicate if you have any experience in the following
                  areas.
                </Typography>
              </Grid>
              {props.experiences &&
                props.experiences.map((experience, expIndex: number) => {
                  let index = props.selectedExperiences.findIndex(
                    (exp) => exp.id === experience.id
                  );
                  let current = '';
                  if (index >= 0) {
                    current = props.selectedExperiences[index].value;
                  }
                  return (
                    <Grid item xs={12} sm={6} key={expIndex}>
                      <label className={classes.label}>
                        {experience.label}
                      </label>
                      <TextField
                        value={current}
                        onChange={(e) => {
                          props.handleExperience(
                            experience.id,
                            e.currentTarget.getAttribute('data-value')
                          );
                        }}
                        variant="outlined"
                        label="Please select"
                        select
                        fullWidth
                      >
                        <MenuItem value={'Yes'} style={{ fontSize: '14px' }}>
                          Yes
                        </MenuItem>
                        <MenuItem value={'No'} style={{ fontSize: '14px' }}>
                          No
                        </MenuItem>
                      </TextField>
                    </Grid>
                  );
                })}
            </Grid>
          </div>
          <ThreeButtonsGroup
            nextStep={props.nextStep}
            prevStep={props.prevStep}
            saveAndExit={props.saveAndExit}
          />
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default withLayout(Skills, DefaultLayout, {
  Step: FormSteps.SkillsExperiences,
});
