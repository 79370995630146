import { createMuiTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});

export const Theme = createMuiTheme({
  palette: {
    primary: {
      main: '#71bf43',
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      'Poppins',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontFamily: [
        'Poppins',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
      ].join(','),
      fontSize: 48,
      fontWeight: 700,
    },
    h2: {
      fontFamily: [
        'Poppins',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
      ].join(','),
      fontSize: 36,
      fontWeight: 700,
    },
    h3: {
      fontFamily: [
        'Poppins',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
      ].join(','),
      fontSize: 22,
      lineHeight: '28px',
      fontWeight: 600,
      [breakpoints.up('sm')]: {
        fontSize: 28,
        lineHeight: '36px',
      },
    },
    h4: {
      fontFamily: ['Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(
        ','
      ),
      fontSize: 18,
      lineHeight: '24px',
      fontWeight: 400,
    },
    h5: {
      fontFamily: [
        'Poppins',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
      ].join(','),
      fontSize: 18,
      fontWeight: 300,
    },
    h6: {
      fontFamily: [
        'Poppins',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
      ].join(','),
      fontSize: 15,
      fontWeight: 300,
    },
    body1: {
      fontFamily: ['Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(
        ','
      ),
      fontSize: 17,
      fontWeight: 400,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
        fontFamily: 'Poppins',
        fontSize: 16,
        lineHeight: '24px',
        fontWeight: 400,
      },
      containedPrimary: {
        '& hover': {
          backgroundColor: '#d5d5d5 !important',
          boxShadow:
            '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
        },
        hover: {
          backgroundColor: '#d5d5d5 !important',
          boxShadow:
            '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: '14px',
      },
    },
    MuiContainer: {
      root: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: '10px 16px',
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translateY(12px) translatex(14px)',
      },
    },
    MuiSvgIcon: {
      root: {
        width: '22px',
        height: '22px',
      },
    },
    MuiSelect: {
      icon: {
        top: 'unset',
      },
    },
    MuiAccordion: {
      root: {
        border: '0px solid rgba(0, 0, 0, .125)',
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: 0,
      },
    },
  },
});
