import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { buttonStyles } from '../../common/Styles';
import { GreenButton } from './FormControl';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'calc(100% - 60px)',
    maxWidth: '950px',
    margin: '30px',
    [theme.breakpoints.up('sm')]: {
      margin: '0',
      width: '100%',
    },
  },
}));

const ThreeButtonsGroup = (props: any) => {
  const buttonClasses = buttonStyles();
  const classes = useStyles();

  const handleNext = () => {
    props.nextStep();
  };

  const handleBack = () => {
    props.prevStep();
  };

  const handleSave = () => {
    props.saveAndExit();
  };

  return (
    <div className={classes.buttonContainer}>
      <Button
        size="large"
        variant="contained"
        className={buttonClasses.backButton}
        fullWidth
        onClick={handleBack}
      >
        Back
      </Button>
      <Button
        size="large"
        variant="contained"
        className={buttonClasses.saveExitButton}
        fullWidth
        onClick={handleSave}
      >
        Save & Exit
      </Button>
      <GreenButton
        variant="contained"
        size="large"
        className={buttonClasses.nextButton}
        fullWidth
        disabled={props.nextButtonDisabled ? props.nextButtonDisabled : false}
        onClick={handleNext}
      >
        Next
      </GreenButton>
    </div>
  );
};

export default ThreeButtonsGroup;
