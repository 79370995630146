export const HomePageURL = 'https://www.xpotalent.com.au/';

export const GoogleReCaptchaSiteKey =
  '6LcV9LcZAAAAAJ-yfOF4JNeqb4OesSbRE9dGCCOt';

export const GoogleReCaptchaAction = 'create';

export enum FormSteps {
  Welcome = 1,
  PersonalDetails = 2,
  SkillsExperiences = 3,
  Qualifications = 4,
  LicencesCertificates = 5,
  SocialMedia = 6,
  PhotoUpload = 7,
  VideoUpload = 8,
  TermsandConditions = 9,
  ThankYou = 10,
}

export const REQUIREDFIELD = '* Required field';
