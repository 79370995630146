import React, { useState, useEffect } from 'react';
import {
  Grid,
  Container,
  ThemeProvider,
  Typography,
  Button,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { Theme } from '../../Theme';
// import { XPOUploadCropPicture } from '@ap-materix/materix-shared-components';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import ThreeButtonsGroup from '../../components/FormControl/ThreeButtonsGroup';
import { buttonStyles, textStyles } from '../../common/Styles';
import Dropzone from 'react-dropzone';
import { saveDataService } from '../../services/postDataService';
import withLayout from '../Layout/WithLayout';
import DefaultLayout from '../Layout/Default';
import { FormSteps } from '../../constant/constants';
import { orange } from '@material-ui/core/colors';
import { GreenButton } from '../FormControl/FormControl';
import { useRef } from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 'calc(100vh - 150px)',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      margin: '30px',
      flexGrow: '1',
      width: 'calc(100% - 60px)',
      maxWidth: '950px',
      minHeight: 'calc(100vh - 210px)',
    },
  },
  grid: {
    margin: '20px 22px',
    padding: 0,
    width: 'calc(100% - 44px)',
    [theme.breakpoints.up('sm')]: {
      margin: '-8px',
      paddingBottom: '10px',
    },
  },
  imgPreview: {
    width: '240px',
    height: 'auto',
  },
  errorText: {
    color: '#f44336',
    fontSize: '0.75rem',
    margin: '0',
    display: 'block',
  },

  center: {
    textAlign: 'center',
    margin: '20px',
  },
  fileName: {
    color: orange[500],
    textAlign: 'center',
  },
  displayN: {
    display: 'none',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

interface IPhotoUploadProps {
  nextStep: () => void;
  prevStep: () => void;
  saveAndExit: () => void;
  handleChange: (input: string) => (e: any) => void;
  handleDataInput: (input: string, dataItem: string) => void;
  photo: string;
  uuid: string;
  email: string;
}

const PhotoUpload = (props: IPhotoUploadProps) => {
  const classes = useStyles();
  const buttonClasses = buttonStyles();
  // File uploader
  const textClass = textStyles();
  const mimeAllowed = 'image/png, image/jpg, image/jpeg, image/gif';
  const maximumFileSize = 20 * 1024 * 1024;
  const [error, setError] = useState<string>();
  const [fileName, setFileName] = useState<string>('');
  const [src, setSrc] = useState<string>();
  const [uploading, setUploading] = useState(false);
  const [photoURL, setPhotoURL] = useState<string>();
  const [crop, setCrop] = useState<any>({
    unit: '%',
    width: 30,
    aspect: 1 / 1,
  });
  const [completedCrop, setCompletedCrop] = useState<any>(null);
  const [imageFile, setImageFile] = useState<HTMLImageElement>();

  const clearImageData = () => {
    setFileName(undefined);
    setImageFile(undefined);
    setSrc(undefined);
    setCrop({ aspect: 1 / 1 });
    setCompletedCrop(null);
  };

  useEffect(() => {
    if (props.photo) {
      clearImageData();
      setPhotoURL(props.photo);
    }
  }, [props.photo]);

  const handleNext = () => {
    if (props.photo) {
      props.nextStep();
    } else {
      setError('You must upload a profile photo.');
    }
  };

  const handleBack = () => {
    props.prevStep();
  };

  const onFileSelect = (files: File[]) => {
    if (src) URL.revokeObjectURL(src);
    if (files.length > 1) {
      setError('Please select only one file');
    } else {
      setError(undefined);
      setFileName(files[0].name);
      setCompletedCrop(null);
      setSrc(URL.createObjectURL(files[0]));
    }
  };

  const getCroppedImg = async (): Promise<Blob> => {
    if (imageFile && completedCrop) {
      try {
        const canvas = document.createElement('canvas');
        const scaleX = imageFile.naturalWidth / imageFile.width;
        const scaleY = imageFile.naturalHeight / imageFile.height;
        canvas.width = completedCrop.width;
        canvas.height = completedCrop.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(
          imageFile,
          completedCrop.x * scaleX,
          completedCrop.y * scaleY,
          completedCrop.width * scaleX,
          completedCrop.height * scaleY,
          0,
          0,
          completedCrop.width,
          completedCrop.height
        );

        return new Promise((resolve, reject) => {
          canvas.toBlob((blob: Blob) => {
            resolve(blob);
          }, 'image/jpeg');
        });
      } catch (e) {
        setError('Failed to crop, please try again.');
      }
    } else {
      setError('Please crop your photo before uploading.');
    }
  };

  const saveProfilePhoto = async (): Promise<boolean> => {
    setUploading(true);
    let formData = new FormData();
    return await getCroppedImg()
      .then((file) => {
        formData.append('photo', file, fileName);
        return saveDataService
          .uploadFileDataService(formData, props.uuid, props.email)
          .then((res) => {
            setError(undefined);
            setUploading(false);
            clearImageData();
            setPhotoURL(res.talentApplication.photo);
            props.handleDataInput('photo', res.talentApplication.photo);
            return true;
          })
          .catch(() => {
            setUploading(false);
            return false;
          });
      })
      .catch(() => {
        setError('Failed to crop, please try again.');
        setUploading(false);
        return false;
      });
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={Theme}>
        <Container className={classes.container}>
          <Dialog
            open={src !== undefined}
            onClose={() => {
              setSrc(undefined);
              setImageFile(undefined);
              setCrop({ aspect: 1 / 1 });
            }}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
          >
            <DialogTitle>
              <h3>
                Press and drag on the image below to select the square used for
                your profile photo.
              </h3>
            </DialogTitle>
            <DialogContent>
              <div>
                {src && (
                  <ReactCrop
                    src={src}
                    crop={crop}
                    onChange={(c) => setCrop(c)}
                    onComplete={(c) => setCompletedCrop(c)}
                    onImageLoaded={setImageFile}
                  />
                )}
                {error && <p className={classes.errorText}>{error}</p>}
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setSrc(undefined);
                  setImageFile(undefined);
                  setCrop({ aspect: 1 / 1 });
                }}
              >
                Cancel
              </Button>
              <GreenButton
                variant="contained"
                size="large"
                className={buttonClasses.nextButton}
                fullWidth
                onClick={saveProfilePhoto}
                disabled={uploading}
              >
                Save
              </GreenButton>
            </DialogActions>
          </Dialog>
          <Grid container spacing={2} className={classes.grid}>
            <Grid item xs={12}>
              <Typography variant="h3">Photo Upload</Typography>
              <Typography variant="h5">
                <p>
                  Almost there! In this section, you are{' '}
                  <strong>required</strong> to upload a photo of yourself.
                </p>
                <p>
                  If you cant upload your photo because it is too large, try
                  reducing the photo size. If you don't know how to reduce your
                  photo size on iPhone or Android, read{' '}
                  <a
                    href="https://itstillworks.com/reduce-picture-size-before-sending-iphone-37739.html"
                    target="_blank"
                  >
                    this article.
                  </a>
                </p>
                <p>
                  <strong>Tip:</strong> Please ensure that you are the only
                  person in the photo, you can clearly see your face, and there
                  is sufficient lighting.
                </p>
              </Typography>
            </Grid>

            <Grid container spacing={2} justify="center">
              <Grid item xs={12} md={8} lg={7}>
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    onFileSelect(acceptedFiles);
                  }}
                  accept={mimeAllowed}
                  onDropRejected={() =>
                    setError(
                      'That file was not accepted, please select a file matching the size and types given.'
                    )
                  }
                  maxSize={maximumFileSize}
                  multiple={false}
                  disabled={uploading}
                >
                  {({ getRootProps, getInputProps, acceptedFiles }) => (
                    <section>
                      <div
                        {...getRootProps()}
                        style={{
                          width: '80vw',
                          maxWidth: '367px',
                          display: 'flex',
                          margin: 'auto',
                          backgroundColor: '#71bf43',
                          justifyContent: 'center',
                          color: 'white',
                          padding: '8px',
                          borderRadius: '4px',
                          cursor: 'pointer',
                        }}
                      >
                        <input {...getInputProps()} name={`photo-file`} />
                        <svg
                          width="33"
                          height="32"
                          viewBox="0 0 33 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ transform: 'scale(0.8)' }}
                        >
                          <path
                            d="M10.3336 24.3329C8.25516 24.3343 6.25126 23.5589 4.71503 22.159C3.17881 20.7591 2.22116 18.8357 2.02998 16.7661C1.8388 14.6965 2.42789 12.6302 3.68167 10.9725C4.93545 9.31487 6.76341 8.18557 8.80689 7.8062C9.16582 5.89565 10.1811 4.1706 11.6773 2.92937C13.1734 1.68815 15.0563 1.00879 17.0002 1.00879C18.9442 1.00879 20.8271 1.68815 22.3232 2.92937C23.8193 4.1706 24.8346 5.89565 25.1936 7.8062C27.2302 8.19329 29.0496 9.32532 30.2966 10.9814C31.5437 12.6375 32.129 14.6988 31.9383 16.7631C31.7476 18.8274 30.7946 20.7466 29.2653 22.1462C27.7359 23.5458 25.74 24.3254 23.6669 24.3329M12.0002 15.9995L17.0002 10.9995M17.0002 10.9995L22.0002 15.9995M17.0002 10.9995V30.9995"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <p
                          style={{
                            margin: 'auto 0',
                            paddingLeft: '20px',
                            fontFamily: 'Poppins',
                            fontWeight: 400,
                          }}
                        >
                          Choose file
                        </p>
                      </div>
                    </section>
                  )}
                </Dropzone>
                <div
                  className={textClass.warning}
                  style={{ margin: '20px auto' }}
                >
                  * Accepted file types: {mimeAllowed}. <br />* Maximum file
                  size is {maximumFileSize / 1024 / 1024}MB!
                </div>
                {error && <p className={classes.errorText}>{error}</p>}
                {photoURL &&
                  photoURL.length > 0 &&
                  !uploading &&
                  src === undefined && (
                    <div className={classes.center}>
                      <img
                        src={photoURL}
                        className={classes.imgPreview}
                        alt="Destination"
                      />
                    </div>
                  )}
              </Grid>
            </Grid>
          </Grid>
          {!src && (
            <ThreeButtonsGroup
              nextStep={handleNext}
              prevStep={handleBack}
              saveAndExit={props.saveAndExit}
            />
          )}
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default withLayout(PhotoUpload, DefaultLayout, {
  Step: FormSteps.PhotoUpload,
});
