import React, { useState, useEffect } from 'react';
import UserForm from '../../components/UserForm/UserForm';
import { loadDataService } from '../../services/loadDataService';

import { RouteComponentProps, withRouter } from 'react-router-dom';

import * as QueryString from 'query-string';
import {
  IExperienceValues,
  IFormValue,
  IFormValuePayload,
} from '../../interfaces/formValue.interface';

export enum QueryParamKeys {
  UUID = 'uuid',
  EMAIL = 'email',
}

const Ambassador: React.FunctionComponent<RouteComponentProps> = (
  props: any
) => {
  const {
    skills,
    experiences,
    qualifications,
    certificates,
    inductions,
    referrerTypes,
  } = props;
  const [ambassadorInfo, setAmbassadorInfo] = useState(null);
  const [selectedSkills, setSelectedSkills] = useState<IFormValuePayload[]>();
  const [selectedExperiences, setExperiences] = useState<IExperienceValues[]>();
  const [selectedQualifications, setSelectedQualifications] = useState<
    IFormValuePayload[]
  >();
  const [selectedCertificates, setSelectedCertificates] = useState<number[]>();
  const [selectedInductions, setSelectedInductions] = useState<number[]>();
  const params = QueryString.parse(props.location.search);
  const uuid = params.uuid;
  const email = params[QueryParamKeys.EMAIL];

  useEffect(() => {
    loadDataService
      .loadAmbassador(uuid, email)
      .then((response: any) => {
        const {
          skills,
          experience,
          qualifications,
          inductionIds,
          certificateIds,
        } = response.talentApplication;
        // setSelectedSkills to be passes as props
        if (skills && skills.length > 0) {
          setSelectedSkills(skills);
        } else {
          setSelectedSkills([]);
        }
        // setExperience to be passes as props
        if (experience && experience.length > 0) {
          setExperiences(experience);
        } else {
          setExperiences([]);
        }
        // setSelectedQualifications to be passes as props
        if (qualifications && qualifications.length > 0) {
          setSelectedQualifications(qualifications);
        } else {
          setSelectedQualifications([]);
        }
        // setSelectedCertificates to be passes as props
        if (certificateIds && certificateIds.length > 0) {
          setSelectedCertificates(certificateIds);
        } else {
          setSelectedCertificates([]);
        }
        // setSelectedCertificates to be passes as props
        if (inductionIds && inductionIds.length > 0) {
          setSelectedInductions(inductionIds);
        } else {
          setSelectedInductions([]);
        }

        if (
          response.talentApplication &&
          response.talentApplication.referrer &&
          response.talentApplication.referrer.startsWith('XPO Talent: ')
        ) {
          setAmbassadorInfo({
            ...response.talentApplication,
            referrer: 'I was referred by a friend',
            referrerName: response.talentApplication.referrer.split(
              'XPO Talent: '
            )[1],
          });
        } else {
          setAmbassadorInfo(response.talentApplication);
        }
      })
      .catch(function (error) {
        props.history.push('/error_404');
      });
  }, [referrerTypes]);

  return (
    ambassadorInfo &&
    selectedCertificates &&
    selectedInductions &&
    selectedExperiences &&
    selectedSkills &&
    selectedQualifications && (
      <UserForm
        skills={skills}
        selectedSkills={selectedSkills}
        experiences={experiences}
        selectedExperiences={selectedExperiences}
        qualifications={qualifications}
        selectedQualifications={selectedQualifications}
        certificates={certificates}
        selectedCertificates={selectedCertificates}
        inductions={inductions}
        selectedInductions={selectedInductions}
        referrerTypes={referrerTypes}
        ambassadorInfo={ambassadorInfo}
      />
    )
  );
};

export default withRouter(Ambassador);
