import { FormControlLabel, Grid, RadioGroup } from '@material-ui/core';
import React from 'react';
import {
  IFormValue,
  IFormValuePayload,
} from '../../interfaces/formValue.interface';
import { GreenRadio } from '../FormControl/FormControl';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: '30px',
    marginBottom: '30px',
    width: '100%',
    borderBottom: '1px solid #A9A9A9',
    [theme.breakpoints.up('sm')]: {
      boxShadow:
        '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
      width: 'calc(100vw - 403px)',
      maxWidth: '950px',
    },
  },
  header: {
    backgroundColor: '#EBEBEB',
    padding: '8px',
    paddingLeft: '30px',
  },
  headerLabel: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '12px',
  },
  row: {
    borderTop: '1px solid #A9A9A9',
    padding: '8px',
    paddingLeft: '30px',
    '& div': {
      display: 'flex',
    },
  },
  radioButton: {
    // margin: 0,
    margin: 'auto',
    '& span': {
      padding: 0,
      margin: 0,
    },
  },
}));

const RadioSelectTable = ({
  displayList,
  title,
  option1,
  option2,
  selectedItems,
  handleSelection,
}: {
  displayList: IFormValue[];
  title: string;
  option1: string;
  option2: string;
  selectedItems: IFormValuePayload[];
  handleSelection: (id: string, value: string, field: string) => void;
}) => {
  const classes = useStyles();
  return (
    <div className={classes.table}>
      <Grid container className={classes.header}>
        <Grid
          item
          xs={3}
          style={{
            textTransform: 'uppercase',
            fontSize: '12px',
          }}
        >
          {title.slice(0, title.length - 1)}
        </Grid>
        <Grid item xs={3} className={classes.headerLabel}>
          None
        </Grid>
        <Grid item xs={3} className={classes.headerLabel}>
          {`${option1[0].toUpperCase()}${option1.slice(1)}`}
        </Grid>
        <Grid item xs={3} className={classes.headerLabel}>
          {`${option2[0].toUpperCase()}${option2.slice(1)}`}
        </Grid>
      </Grid>
      <div>
        {displayList.map((item: IFormValue) => {
          let index = selectedItems
            ? selectedItems.findIndex(
                (element) => `${element.id}` === `${item.id}`
              )
            : -1;
          return (
            <RadioGroup
              aria-label={item.label}
              name={item.id}
              onChange={(e) =>
                handleSelection(
                  e.currentTarget.name,
                  e.currentTarget.value,
                  `selected${title}`
                )
              }
              value={index === -1 ? 'none' : selectedItems[index].value}
              key={item.id}
            >
              <Grid container className={classes.row}>
                <Grid item xs={3}>
                  <span
                    style={{
                      fontFamily: 'Roboto',
                      fontSize: '14px',
                      lineHeight: '20px',
                    }}
                  >
                    {item.label}
                  </span>
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    value="none"
                    control={<GreenRadio />}
                    label=""
                    className={classes.radioButton}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    value={option1}
                    control={<GreenRadio />}
                    label=""
                    className={classes.radioButton}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    value={option2}
                    control={<GreenRadio />}
                    label=""
                    className={classes.radioButton}
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          );
        })}
      </div>
    </div>
  );
};

export default RadioSelectTable;
