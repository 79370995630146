import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';
import { green, red, grey } from '@material-ui/core/colors';

export const buttonStyles = makeStyles((theme) => ({
  saveExitButton: {
    border: 'none',
    color: '#FFF',
    backgroundColor: '#000',
    borderRadius: '5px',
    width: '118px',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('sm')]: {
      width: '173px',
    },
  },
  backButton: {
    border: 'none',
    backgroundColor: '#00ADEF',
    color: '#FFF',
    borderRadius: '5px',
    width: '67px',
    [theme.breakpoints.up('sm')]: {
      width: '173px',
    },
  },
  nextButton: {
    backgroundColor: '#71bf43',
    color: 'white',
    width: '67px',
    [theme.breakpoints.up('sm')]: {
      width: '173px',
    },
    '& hover': {
      backgroundColor: '#d5d5d5 !important',
      boxShadow:
        '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    },
  },
  radioButton: {
    margin: 0,
    marginRight: '15px',
    '&:last-child': {
      marginRight: 0,
    },
  },
  fileUploadButton: {
    background: '#71bf43',
    color: 'white',
    marginTop: theme.spacing(2),
  },
  paddingBottom: {
    paddingBottom: '15px',
  },
  select: {
    margin: theme.spacing(1),
    width: '100%',
  },
  selectPlaceholder: {
    color: '#000',
  },
  dropZone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    marginBottom: 10,
    textAlign: 'center',
  },
  imageCropper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 300,
    marginBottom: 10,
  },
  imageCropperPreview: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 250,
    marginBottom: 10,
    justifyContent: 'space-around',
  },
  imageActionButtons: {
    display: 'flex',
    flexDirection: 'column',
  },
  videoActionButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}));

export const formStyles = makeStyles((theme) => ({
  radioGroup: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
}));

export const marginStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: theme.spacing(2),
  },
}));
export const textStyles = makeStyles((theme) => ({
  error: {
    color: red[700],
    fontSize: '0.75rem',
  },
  succeed: {
    color: green[500],
    padding: '20px 0 30px 20px',
  },
  warning: {
    color: grey[800],
    fontSize: '12px',
  },
}));

export const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
