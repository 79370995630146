import React, { useState, useEffect } from 'react';
import {
  FormControlLabel,
  Grid,
  Checkbox,
  ThemeProvider,
  Typography,
  FormControl,
  RadioGroup,
  makeStyles,
} from '@material-ui/core';
import { IFormValue } from '../../interfaces/formValue.interface';
import ThreeButtonsGroup from '../FormControl/ThreeButtonsGroup';
import { Theme } from '../../Theme';
import withLayout from '../Layout/WithLayout';
import DefaultLayout from '../Layout/Default';
import { FormSteps } from '../../constant/constants';
import { GreenRadio } from '../FormControl/FormControl';

interface ICertificatesProps {
  nextStep: () => void;
  prevStep: () => void;
  saveAndExit: () => void;
  handleCheckboxGroup: (id: number, field: string) => void;
  handleLicenceSelection: (selection: string) => void;
  certificates: IFormValue[];
  selectedCertificates: number[];
  inductions: IFormValue[];
  selectedInductions: number[];
  handleOwnsCar: (selection: boolean | undefined) => void;
  ownsCar: boolean;
  licenceType: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '20px 22px',
    padding: 0,
    width: 'calc(100% - 44px)',
    maxWidth: '950px',
  },
  h3: {
    margin: '0',
    marginBottom: '4px',
  },
  buttonContainer: {
    [theme.breakpoints.up('sm')]: {
      margin: '0 30px',
    },
  },
  radioLabel: {
    margin: 0,
  },
  errorText: {
    color: '#f44336',
    fontSize: '0.75rem',
    margin: '0',
    display: 'block',
  },
  checkboxGrid: {
    padding: 0,
  },
  sectionTitle: {
    paddingBottom: '8px',
  },
}));

interface ISubmissionErrors {
  licence?: string;
  car?: string;
}

const LicencesCertificates = (props: ICertificatesProps) => {
  const [hasLicence, setHasLicence] = useState<boolean>();
  const [errors, setErrors] = useState<ISubmissionErrors>({});
  const classes = useStyles();

  const handleNext = () => {
    let tempErrors: ISubmissionErrors = {};
    if (hasLicence === undefined) {
      tempErrors.licence = 'This section is required.';
    }
    if (
      hasLicence === true &&
      (props.licenceType === undefined || props.licenceType === '')
    ) {
      tempErrors.licence = 'You must select your licence class';
    }
    if (props.ownsCar === undefined) {
      tempErrors.car = 'This section is required.';
    }
    setErrors(tempErrors);
    if (Object.keys(tempErrors).length < 1) {
      props.nextStep();
    }
  };

  const handleBack = () => {
    props.prevStep();
  };

  useEffect(() => {
    if (hasLicence === undefined && props.licenceType.length > 0) {
      setHasLicence(true);
    }
  }, [props.licenceType]);

  return (
    <React.Fragment>
      <ThemeProvider theme={Theme}>
        <Grid container spacing={2} className={classes.container}>
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.h3}>
              Licences & Certificates
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.sectionTitle}>
              Do you have a valid Australian Driver's Licence?*
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={hasLicence === true}
                  onChange={() => {
                    let tempErrors = { ...errors };
                    tempErrors.licence = undefined;
                    setErrors(tempErrors);
                    let licenceResponse;
                    if (hasLicence !== true) {
                      licenceResponse = true;
                    } else {
                      props.handleLicenceSelection('');
                    }
                    setHasLicence(licenceResponse);
                  }}
                  name="driverLicence"
                  color="primary"
                  value={'yes'}
                />
              }
              label="Yes"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={hasLicence === false}
                  onChange={() => {
                    let tempErrors = { ...errors };
                    tempErrors.licence = undefined;
                    setErrors(tempErrors);
                    let licenceResponse;
                    if (hasLicence !== false) {
                      licenceResponse = false;
                    }
                    props.handleLicenceSelection('');
                    setHasLicence(licenceResponse);
                  }}
                  name="driverLicence"
                  color="primary"
                />
              }
              label="No"
            />
            {errors.licence && hasLicence === undefined && (
              <p className={classes.errorText}>{errors.licence}</p>
            )}
          </Grid>
          {hasLicence && (
            <Grid item xs={12} container>
              <Grid item xs={12}>
                <Typography variant="h4" className={classes.sectionTitle}>
                  Please select the class of licence you have:
                </Typography>
              </Grid>
              <FormControl component="fieldset" fullWidth>
                <RadioGroup
                  row
                  aria-label="modelling"
                  name="licence"
                  onChange={(e) => {
                    let tempErrors = { ...errors };
                    tempErrors.licence = undefined;
                    setErrors(tempErrors);
                    props.handleLicenceSelection(e.currentTarget.value);
                  }}
                  value={props.licenceType}
                >
                  <Grid item xs={6} sm={4}>
                    <FormControlLabel
                      value="C"
                      control={<GreenRadio />}
                      label="Car licence"
                      className={classes.radioLabel}
                    />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <FormControlLabel
                      value="LR"
                      control={<GreenRadio />}
                      label="Light Rigid"
                      className={classes.radioLabel}
                    />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <FormControlLabel
                      value="MR"
                      control={<GreenRadio />}
                      label="Medium Rigid"
                      className={classes.radioLabel}
                    />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <FormControlLabel
                      value="HR"
                      control={<GreenRadio />}
                      label="Heavy Rigid"
                      className={classes.radioLabel}
                    />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <FormControlLabel
                      value="HC"
                      control={<GreenRadio />}
                      label="Heavy Combination"
                      className={classes.radioLabel}
                    />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <FormControlLabel
                      value="MC"
                      control={<GreenRadio />}
                      label="Multi Combination"
                      className={classes.radioLabel}
                    />
                  </Grid>
                </RadioGroup>
              </FormControl>
              {errors.licence && (
                <p className={classes.errorText}>{errors.licence}</p>
              )}
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.sectionTitle}>
              Do you have regular access to a car?*
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.ownsCar === true}
                  onChange={() => {
                    let tempErrors = { ...errors };
                    tempErrors.car = undefined;
                    setErrors(tempErrors);
                    let carResponse;
                    if (props.ownsCar !== true) {
                      carResponse = true;
                    }
                    props.handleOwnsCar(carResponse);
                  }}
                  name="ownsCar"
                  color="primary"
                />
              }
              label="Yes"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.ownsCar === false}
                  onChange={() => {
                    let tempErrors = { ...errors };
                    tempErrors.car = undefined;
                    setErrors(tempErrors);
                    let carResponse;
                    if (props.ownsCar !== false) {
                      carResponse = false;
                    }
                    props.handleOwnsCar(carResponse);
                  }}
                  name="ownsCar"
                  color="primary"
                />
              }
              label="No"
            />
            {errors.car && <p className={classes.errorText}>{errors.car}</p>}
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h4" className={classes.sectionTitle}>
              Please indicate whether you have the following induction cards. If
              none, skip.
            </Typography>

            {props.inductions &&
              props.inductions.map((item: IFormValue) => {
                return (
                  <div key={item.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={props.selectedInductions.includes(
                            Number(item.id)
                          )}
                          onChange={(e) =>
                            props.handleCheckboxGroup(
                              Number(item.id),
                              'selectedInductions'
                            )
                          }
                          name={item.id}
                          color="primary"
                        />
                      }
                      label={item.label}
                    />
                  </div>
                );
              })}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.sectionTitle}>
              Please indicate whether you have the following certificates. If
              none, skip.
            </Typography>
          </Grid>
          {props.certificates &&
            props.certificates.map((item: IFormValue) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ padding: 0, paddingLeft: '8px' }}
                  key={item.id}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={props.selectedCertificates.includes(
                          Number(item.id)
                        )}
                        onChange={(e) =>
                          props.handleCheckboxGroup(
                            Number(item.id),
                            'selectedCertificates'
                          )
                        }
                        name={item.id}
                        color="primary"
                      />
                    }
                    label={item.label}
                  />
                </Grid>
              );
            })}
        </Grid>
        <div className={classes.buttonContainer}>
          <ThreeButtonsGroup
            nextStep={handleNext}
            prevStep={handleBack}
            saveAndExit={props.saveAndExit}
          />
        </div>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default withLayout(LicencesCertificates, DefaultLayout, {
  Step: FormSteps.LicencesCertificates,
});
