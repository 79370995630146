import React from 'react';
import { Container, ThemeProvider, Typography } from '@material-ui/core';
import { GreenButton } from '../FormControl/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '../../Theme';
import './Welcome.css';
import withLayout from '../Layout/WithLayout';
import DefaultLayout from '../Layout/Default';
import { FormSteps } from '../../constant/constants';
import { buttonStyles } from '../../common/Styles';

interface IWelcome {
  nextStep: () => void;
  handleChange: (input: string) => (e: any) => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 'calc(100vh - 150px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '30px',
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
      maxWidth: '850px',
    },
  },
  bottomButton: {
    width: '100%',
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      width: 'calc(100vw - 423px)',
      maxWidth: '950px',
    },
  },
}));

const Welcome = (props: IWelcome) => {
  const classes = useStyles();
  const buttonClasses = buttonStyles();

  const handleNext = (e: any) => {
    e.preventDefault();
    props.nextStep();
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={Theme}>
        <Container maxWidth="lg" className={classes.container}>
          <div>
            <Typography variant="h3">Welcome to XPO Talent</Typography>

            <p>
              Thank you for your interest in becoming a Brand Ambassador. To
              complete this application, we will ask a few questions about
              yourself and your background.
            </p>
            <p>
              We also ask that you <strong>provide a headshot photo</strong>, as
              well as a recorded <strong>1 min video of yourself</strong>,
              letting us know a bit about you, and why you are applying to be a
              Brand Ambassador at XPO Talent.&nbsp;&nbsp;
              <u>
                We recommend you have the video and photo on file before you
                commence the application form.
              </u>
              &nbsp;Both are <strong>required</strong> to complete your
              application.
            </p>
            <p>
              It will take approximately <strong>10-15 min</strong> to complete
              this application. <br />
              When you are ready, please click Next.
            </p>
          </div>
          <div className={classes.bottomButton}>
            <GreenButton
              size="large"
              variant="contained"
              className={buttonClasses.nextButton}
              style={{ marginLeft: 'auto' }}
              onClick={handleNext}
            >
              Next
            </GreenButton>
          </div>
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default withLayout(Welcome, DefaultLayout, { Step: FormSteps.Welcome });
