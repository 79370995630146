import React, { Component } from 'react';

import Welcome from '..//Welcome';
import PersonalDetails from '../PersonalDetails/PersonalDetails';
import Skills from '../Skills';
import Qualifications from '../Qualifications';
import LicencesCertificates from '../LicencesCertificates';
import SocialMedia from '../SociaMedia';
import PhotoUpload from '../PhotoUpload/PhotoUpload';
import VideoUpload from '../VideoUpload/VideoUpload';
import TermsandConditions from '../TermsandConditions';
import ThankYou from '../ThankYou';
import Modal, { ModalTypes } from '../XPO/Modal';
import { HomePageURL, FormSteps } from '../../constant/constants';
import { saveDataService } from '../../services/postDataService';
import {
  IExperienceValues,
  IFormValue,
  IFormValuePayload,
} from '../../interfaces/formValue.interface';

interface IUserFormProps {
  skills: IFormValue[];
  selectedSkills?: IFormValuePayload[];
  experiences: IFormValue[];
  selectedExperiences?: IExperienceValues[];
  qualifications: IFormValue[];
  selectedQualifications?: IFormValuePayload[];
  certificates: IFormValue[];
  selectedCertificates?: number[];
  inductions: IFormValue[];
  selectedInductions?: number[];
  referrerTypes: string[];
  selectedReferrer?: IFormValue[];
  ambassadorInfo?: any;
}

interface IExperiencePayload {
  experience?: IFormValuePayload[];
  experienceNotes: string;
}

interface ILicencesCertificatesPayload {
  licence: string;
  ownsCar: boolean;
  inductionIds: number[];
  certificateIds: number[];
}

interface ISocialMediaPayload {
  socialMedia: {
    facebook: string;
    instagram: string;
    tiktok: string;
    linkedin: string;
    influencer?: boolean;
  };
  referrer?: string;
}

interface ITermsandConditions {
  termsAndConditions?: {
    ageConfirmation: boolean;
    personalDetailsConfirmation: boolean;
    correctInformationConfirmation: boolean;
    rightToWorkConfirmation: boolean;
    visaStatus: string;
  };
}

interface IUserFormState {}

export class UserForm extends Component<IUserFormProps, IUserFormState> {
  constructor(props: any) {
    super(props);
  }

  state = {
    step: FormSteps.Welcome,
    openModal: false,
    modalType: ModalTypes.ERROR,
    modalTitle: '',
    modalMessage: '',
    modalConfirmed: false,
    recaptchaToken: null,
    uuid: '',
    firstName: '',
    lastName: '',
    gender: 'F',
    email: '',
    phone: '',
    streetAddress: '',
    streetAddress2: '',
    suburbId: '',
    city: '',
    state: '',
    postcode: '',
    dateOfBirth: null,
    experienceNotes: '',
    driverLicence: false,
    licenceType: '',
    ownsCar: undefined,
    instagram: '',
    tikTok: '',
    linkedIn: '',
    facebook: '',
    influencer: undefined,
    referrer: '',
    referrerName: '',
    photo: null,
    video: null,
    videoPreview: null,
    noPhoto: '',
    noVideo: '',
    ageConfirmation: false,
    personalDetailsConfirmation: false,
    correctInformationConfirmation: false,
    rightToWorkConfirmation: false,
    visaStatus: '',
    selectedSkills: [],
    selectedQualifications: [],
    selectedInductions: [],
    selectedExperiences: [],
    selectedCertificates: [],
  };

  componentDidMount() {
    if (this.props.ambassadorInfo) {
      const {
        ambassadorInfo,
        selectedSkills,
        selectedExperiences,
        selectedQualifications,
        selectedCertificates,
        selectedInductions,
      } = this.props;
      const {
        uuid,
        email,
        firstName,
        lastName,
        gender,
        phone,
        streetAddress,
        streetAddress2,
        dateOfBirth,
        experienceNotes,
        socialMedia,
        referrer,
        referrerName,
        licence,
        ownsCar,
        photo,
        video,
        videoPreview,
      } = ambassadorInfo;
      this.setState({
        uuid,
        firstName,
        lastName,
        gender,
        email,
        phone,
        streetAddress,
        streetAddress2,
        dateOfBirth,
        experienceNotes,
        referrer,
        referrerName,
        suburbId: ambassadorInfo.suburb ? ambassadorInfo.suburb.id : '',
        city: ambassadorInfo.suburb ? ambassadorInfo.suburb.name : '',
        state: ambassadorInfo.suburb ? ambassadorInfo.suburb.state : '',
        postcode: ambassadorInfo.suburb ? ambassadorInfo.suburb.postcode : '',
        facebook: socialMedia ? socialMedia.facebook : '',
        instagram: socialMedia ? socialMedia.instagram : '',
        linkedIn: socialMedia ? socialMedia.linkedin : '',
        tikTok: socialMedia ? socialMedia.tiktok : '',
        influencer: socialMedia ? socialMedia.influencer : undefined,
        licenceType: licence ? licence : '',
        driverLicence: licence !== '' || null !== licence ? true : false,
        ownsCar,
        photo,
        video,
        videoPreview,
        selectedSkills: selectedSkills ? selectedSkills : [],
        selectedQualifications: selectedQualifications
          ? selectedQualifications
          : [],
        selectedInductions: selectedInductions ? selectedInductions : [],
        selectedExperiences: selectedExperiences ? selectedExperiences : [],
        selectedCertificates: selectedCertificates ? selectedCertificates : [],
      });
    }
  }

  // Used to convert array into object for showing selected values
  convertArrayToObj = (array, value = null) => {
    for (let i = 0; i < array.length; i++) {
      this.setState({ [array[i].id]: value ? array[i].label.value : true });
    }
    return;
  };

  // construct personal details FormData
  formatDate = (date: any) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  constructExperience = (): IExperiencePayload => {
    let experienceArr: IFormValuePayload[] = [];
    let payload: IExperiencePayload = {
      experience: [],
      experienceNotes: '',
    };

    let obj: any = this.state; // add obj: any to avoid typescript error
    for (const prop in obj) {
      if (prop !== 'experienceNotes' && prop.slice(0, 10) === 'experience') {
        experienceArr.push({ id: prop.slice(10), value: obj[prop] });
      }
    }

    payload.experience = experienceArr;
    payload.experienceNotes = this.state.experienceNotes;

    return payload;
  };

  constructTermsandConditions = (): ITermsandConditions => {
    let payload: ITermsandConditions = {};
    payload = {
      termsAndConditions: {
        ageConfirmation: this.state.ageConfirmation,
        personalDetailsConfirmation: this.state.personalDetailsConfirmation,
        correctInformationConfirmation: this.state
          .correctInformationConfirmation,
        rightToWorkConfirmation: this.state.rightToWorkConfirmation,
        visaStatus: this.state.visaStatus,
      },
    };

    return payload;
  };

  constructLicencesCertificates = (): ILicencesCertificatesPayload => {
    let payload: ILicencesCertificatesPayload = {
      inductionIds: [...this.state.selectedInductions],
      certificateIds: [...this.state.selectedCertificates],
      ownsCar: this.state.ownsCar,
      licence: this.state.licenceType,
    };

    return payload;
  };

  constructSocialMedia = (): ISocialMediaPayload => {
    let payload: ISocialMediaPayload = {
      socialMedia: {
        facebook: '',
        instagram: '',
        tiktok: '',
        linkedin: '',
      },
    };

    payload.socialMedia.facebook = this.state.facebook;
    payload.socialMedia.instagram = this.state.instagram;
    payload.socialMedia.tiktok = this.state.tikTok;
    payload.socialMedia.linkedin = this.state.linkedIn;

    if (
      this.state.referrerName !== '' &&
      this.state.referrer === 'I was referred by a friend'
    ) {
      payload.referrer = 'XPO Talent: ' + this.state.referrerName;
    } else {
      payload.referrer = this.state.referrer;
    }
    if (this.state.influencer !== undefined) {
      payload.socialMedia.influencer = this.state.influencer;
    }
    return payload;
  };

  constructPhotoUploadFD = () => {
    let formdata = new FormData();
    formdata.append('noPhoto', this.state.noPhoto);
    return formdata;
  };

  constructVideoUploadFD = () => {
    let formdata = new FormData();
    formdata.append('noVideo', this.state.noVideo);
    return formdata;
  };

  constructPersonalDetailsFD = () => {
    const {
      recaptchaToken,
      firstName,
      lastName,
      gender,
      email,
      phone,
      streetAddress,
      streetAddress2,
      suburbId,
      dateOfBirth,
    } = this.state;
    let formdata = new FormData();
    formdata.append('g-recaptcha-response', recaptchaToken);
    formdata.append('firstName', firstName);
    formdata.append('lastName', lastName);
    formdata.append('gender', gender);
    formdata.append('phone', phone);
    formdata.append('email', email);
    formdata.append('streetAddress', streetAddress);
    formdata.append('streetAddress2', streetAddress2);
    formdata.append('suburbId', suburbId);
    formdata.append('dateOfBirth', this.formatDate(dateOfBirth));

    return formdata;
  };

  goNextStep = (nextStep: FormSteps = null) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    const { step } = this.state;
    if (!nextStep)
      this.setState({
        step: step + 1,
      });
  };

  openErrorModal = () => {
    this.setState({
      openModal: true,
      modalType: ModalTypes.ERROR,
      modalTitle: 'Oops! Something went wrong...',
      modalMessage:
        'Something has gone wrong in processing your request. Please email support@apmaterix.com for assistance.',
    });
  };

  // Proceed to next step
  nextStep = (nextStep: FormSteps = null) => {
    const { step } = this.state;
    switch (step) {
      case FormSteps.Welcome:
        this.setState({
          step: step + 1,
        });

        break;

      case FormSteps.PersonalDetails:
        let formdataPD: FormData = this.constructPersonalDetailsFD();

        if (this.state.uuid.length > 0) {
          saveDataService
            .updateFormDataService(
              formdataPD,
              'multipart/form-data',
              this.state.uuid,
              this.state.email
            )
            .then(() => {
              this.goNextStep(nextStep);
            })
            .catch(() => {
              this.openErrorModal();
            });
        } else
          saveDataService
            .postFormDataService(formdataPD)
            .then((res: any) => {
              this.setState({ uuid: res.talentApplication.uuid });
              this.goNextStep(nextStep);
            })
            .catch(() => {
              this.openErrorModal();
            });

        break;

      case FormSteps.SkillsExperiences:
        let obj = {
          skills: this.state.selectedSkills,
          experience: this.state.selectedExperiences,
        };

        saveDataService
          .updateFormDataService(
            obj,
            'application/json',
            this.state.uuid,
            this.state.email
          )
          .then(() => {
            this.goNextStep(nextStep);
          })
          .catch(() => {
            this.openErrorModal();
          });

        break;

      case FormSteps.Qualifications:
        saveDataService
          .updateFormDataService(
            { qualifications: this.state.selectedQualifications },
            'application/json',
            this.state.uuid,
            this.state.email
          )
          .then(() => {
            this.goNextStep(nextStep);
          })
          .catch(() => {
            this.openErrorModal();
          });
        break;

      case FormSteps.LicencesCertificates:
        saveDataService
          .updateFormDataService(
            this.constructLicencesCertificates(),
            'application/json',
            this.state.uuid,
            this.state.email
          )
          .then(() => {
            this.goNextStep(nextStep);
          })
          .catch(() => {
            this.openErrorModal();
          });

        break;

      case FormSteps.SocialMedia:
        saveDataService
          .updateFormDataService(
            this.constructSocialMedia(),
            'application/json',
            this.state.uuid,
            this.state.email
          )
          .then(() => {
            this.goNextStep(nextStep);
          })
          .catch(() => {
            this.openErrorModal();
          });

        break;

      case FormSteps.PhotoUpload:
        saveDataService
          .updateFormDataService(
            this.constructPhotoUploadFD(),
            'multipart/form-data',
            this.state.uuid,
            this.state.email
          )
          .then(() => {
            this.goNextStep(nextStep);
          })
          .catch(() => {
            this.openErrorModal();
          });

        break;

      case FormSteps.VideoUpload:
        saveDataService
          .updateFormDataService(
            this.constructVideoUploadFD(),
            'multipart/form-data',
            this.state.uuid,
            this.state.email
          )
          .then(() => {
            this.goNextStep(nextStep);
          })
          .catch(() => {
            this.openErrorModal();
          });
        break;

      case FormSteps.TermsandConditions:
        saveDataService
          .updateFormDataService(
            this.constructTermsandConditions(),
            'application/json',
            this.state.uuid,
            this.state.email
          )
          .then(() => {
            this.goNextStep(nextStep);
          })
          .catch(() => {
            this.openErrorModal();
          });
        break;

      default:
    }
  };

  // Go back to previous step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  // save & exit
  saveAndExit = () => {
    // Call Axios to Save the data here
    this.setState({
      openModal: true,
      modalType: ModalTypes.SAVE,
      modalTitle: 'Are you sure you’d like to exit? ',
      modalMessage:
        'Your progress has been saved. You can come back to finish anytime by following the link in your email.',
    });
    this.nextStep(FormSteps.ThankYou);
  };

  handleRadioGroup = (id: string, value: string, field: string) => {
    let tempArr: IFormValuePayload[] = [...this.state[field]];
    let newArr = tempArr.filter((item: IFormValuePayload) => item.id !== id);
    if (value === 'none') {
    } else {
      let newItem: IFormValuePayload = { id, value };
      if (field === 'Skills') {
        newItem.details = '';
      }
      newArr.push(newItem);
    }
    this.setState({
      [field]: newArr,
    });
  };

  handleCheckboxGroup = (id: number, field: string) => {
    let newArr = [...this.state[field]];
    if (newArr.includes(id)) {
      newArr = newArr.filter((current) => current !== id);
    } else {
      newArr.push(id);
    }
    this.setState({ [field]: newArr });
  };

  handleLicenceSelection = (selection: string) => {
    this.setState({ licenceType: selection });
  };

  handleExperience = (id: string, input: string) => {
    let newExperience = [...this.state.selectedExperiences];
    newExperience = newExperience.filter((experience) => experience.id !== id);
    if (input.length > 0) {
      let newItem = { id, value: input };
      newExperience.push(newItem);
    }

    this.setState({ selectedExperiences: newExperience });
  };

  handleOwnsCar = (selection: boolean | undefined) => {
    this.setState({ ownsCar: selection });
  };

  handleInfluencer = (selection: boolean | undefined) => {
    this.setState({ influencer: selection });
  };

  // Handle fields change
  handleCheckbox = (input: string) => (e: any) => {
    this.setState({ [input]: e.target.checked });
  };

  handleDataInput = (input: string, dataItem: string) => {
    this.setState({ [input]: dataItem });
  };

  handleChange = (input: string) => (e: any) => {
    this.setState({ [input]: e.target.value });
  };

  // Handle state postal code change
  handleStatePostalCode = (input: string, value: string) => {
    this.setState({ [input]: value });
  };

  handleSkillDetails = (index: number, details: string) => {
    let updatedSkills = [...this.state.selectedSkills];
    updatedSkills[index].details = details;
    this.setState(updatedSkills);
  };

  handleVideo = (file: File) => {
    this.setState({
      video: file,
    });
  };

  handleModalClose = () => {
    this.setState({
      openModal: false,
    });
  };

  handleModalConfirm = () => {
    this.setState({
      openModal: false,
      modalConfirmed: true,
    });
    window.location.href = HomePageURL;
  };

  fetchComponentForNextStep = () => {
    const { step } = this.state;
    const values = { ...this.state };

    switch (step) {
      case FormSteps.Welcome:
        return (
          <Welcome nextStep={this.nextStep} handleChange={this.handleChange} />
        );
      case FormSteps.PersonalDetails:
        return (
          <PersonalDetails
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            handleDataInput={this.handleDataInput}
            handleStatePostalCode={this.handleStatePostalCode}
            values={values}
          />
        );

      case FormSteps.SkillsExperiences:
        return (
          <Skills
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            saveAndExit={this.saveAndExit}
            handleSelection={this.handleRadioGroup}
            handleSkillDetails={this.handleSkillDetails}
            formValues={this.props.skills}
            experiences={this.props.experiences}
            handleExperience={this.handleExperience}
            selectedExperiences={values.selectedExperiences}
            selectedSkills={values.selectedSkills}
            values={values}
          />
        );

      case FormSteps.Qualifications:
        return (
          <Qualifications
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            saveAndExit={this.saveAndExit}
            handleChange={this.handleRadioGroup}
            selectedQualifications={values.selectedQualifications}
            formValues={this.props.qualifications}
            values={values}
          />
        );

      case FormSteps.LicencesCertificates:
        return (
          <LicencesCertificates
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            saveAndExit={this.saveAndExit}
            handleCheckboxGroup={this.handleCheckboxGroup}
            certificates={this.props.certificates}
            selectedCertificates={values.selectedCertificates}
            inductions={this.props.inductions}
            selectedInductions={values.selectedInductions}
            handleLicenceSelection={this.handleLicenceSelection}
            ownsCar={values.ownsCar}
            handleOwnsCar={this.handleOwnsCar}
            licenceType={values.licenceType}
          />
        );

      case FormSteps.SocialMedia:
        return (
          <SocialMedia
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            saveAndExit={this.saveAndExit}
            handleChange={this.handleChange}
            influencer={values.influencer}
            handleInfluencer={this.handleInfluencer}
            formValues={this.props.referrerTypes}
            referrerName={values.referrerName}
            referrer={values.referrer}
            values={values}
          />
        );
      case FormSteps.PhotoUpload:
        return (
          <PhotoUpload
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            saveAndExit={this.saveAndExit}
            handleChange={this.handleChange}
            handleDataInput={this.handleDataInput}
            email={values.email}
            uuid={values.uuid}
            photo={values.photo}
          />
        );

      case FormSteps.VideoUpload:
        return (
          <VideoUpload
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            saveAndExit={this.saveAndExit}
            handleChange={this.handleChange}
            handleDataInput={this.handleDataInput}
            handleVideo={this.handleVideo}
            video={values.video}
            videoPreview={values.videoPreview}
            email={values.email}
            uuid={values.uuid}
          />
        );

      case FormSteps.TermsandConditions:
        return (
          <TermsandConditions
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            saveAndExit={this.saveAndExit}
            handleCheckbox={this.handleCheckbox}
            handleChange={this.handleChange}
            values={values}
          />
        );

      case FormSteps.ThankYou:
        return <ThankYou />;

      default:
    }
  };

  render() {
    const { openModal, modalType, modalTitle, modalMessage } = this.state;
    return (
      <React.Fragment>
        {openModal && (
          <Modal
            type={modalType}
            open={openModal}
            title={modalTitle}
            content={modalMessage}
            handleClose={this.handleModalClose}
            handleConfirm={this.handleModalConfirm}
          />
        )}
        {this.fetchComponentForNextStep()}
      </React.Fragment>
    );
  }
}

export default UserForm;
