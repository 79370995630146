import React from 'react';
import { ThemeProvider, Typography } from '@material-ui/core';
import { HomePageURL } from '../../constant/constants';
import { WhiteButton } from '../FormControl/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '../../Theme';
import './styles.css';

const useStyles = makeStyles((theme) => ({
  bottom: {
    marginTop: 'auto',
  },
  title: {
    color: 'white',
    textAlign: 'center',
    padding: '10px 20px 0',
  },
  content: {
    padding: '30px',
    textAlign: 'center',
    color: 'white',
  },
}));

const ThankYou = (props: any) => {
  const classes = useStyles();

  return (
    <div className="container">
      <ThemeProvider theme={Theme}>
        <div className="body">
          <div>
            <svg
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.9999 0C11.1929 0 0 11.1929 0 24.9999C0 38.807 11.1929 49.9999 24.9999 49.9999C38.807 49.9999 49.9999 38.807 49.9999 24.9999C49.9852 11.199 38.801 0.0147531 24.9999 0ZM24.9999 46.4285C13.1653 46.4285 3.57139 36.8346 3.57139 24.9999C3.57139 13.1653 13.1653 3.57139 24.9999 3.57139C36.8346 3.57139 46.4285 13.1653 46.4285 24.9999C46.4157 36.8294 36.8294 46.4157 24.9999 46.4285Z"
                fill="white"
              />
              <path
                d="M38.7186 14.8089C38.0266 14.1406 36.9295 14.1406 36.2376 14.8089L19.6429 31.4035L13.7625 25.5231C13.0774 24.8137 11.9468 24.7941 11.2375 25.4793C10.5281 26.1644 10.5086 27.2949 11.1937 28.0042C11.208 28.0191 11.2227 28.0338 11.2375 28.0481L18.3804 35.191C19.0777 35.8881 20.2081 35.8881 20.9054 35.191L38.7625 17.3338C39.4477 16.6245 39.428 15.4941 38.7186 14.8089Z"
                fill="white"
              />
            </svg>
          </div>
          <Typography variant="h3" className={classes.title}>
            Thank you for your application!
          </Typography>

          <Typography variant="h5" className={classes.content}>
            We have received your information and will review it shortly.
          </Typography>

          <Typography variant="h5" className={classes.content}>
            Should your application be successful, we will be in touch with next
            steps.
          </Typography>
          <WhiteButton
            size="large"
            variant="outlined"
            className={classes.bottom}
            fullWidth
            onClick={() => (window.location.href = HomePageURL)}
          >
            Home
          </WhiteButton>
        </div>
      </ThemeProvider>
    </div>
  );
};

export default ThankYou;
