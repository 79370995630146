import React from 'react';
import { green, grey } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import { Radio, Button } from '@material-ui/core';

export const GreenRadio = withStyles({
  root: {
    color: '#A9A9A9',
    marginLeft: '-6px',
    '&$checked': {
      color: '#71BF43',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export const GreenButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#71BF43'),
    backgroundColor: '#71bf43',
  },
}))(Button);

export const WhiteButton = withStyles((theme) => ({
  root: {
    width: '127px',
    color: '#000000',
    backgroundColor: '#FFFFFF',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#d5d5d5',
      color: '#FFFFFF',
      boxShadow:
        '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    },
    [theme.breakpoints.up('sm')]: {
      width: '180px',
    },
  },
}))(Button);

export const BlackButton = withStyles((theme) => ({
  root: {
    width: '127px',
    backgroundColor: '#000000',
    color: '#FFFFFF',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#d5d5d5',
      boxShadow:
        '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    },
    [theme.breakpoints.up('sm')]: {
      width: '180px',
    },
  },
}))(Button);
